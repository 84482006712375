import React, { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import * as styles from '../styles/verify-email.module.scss';
import awsconfig from '../backendConfigProvider';
import { toaster } from '../services/utils';
import changeSync from '../services/DataStoreConfigure';

Amplify.configure(awsconfig);

type Props = {
  location: Location
}

/**
 *
 *
 * @return {*}
 */
const VerifyEmail: React.FC<Props> = ({ location }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  /**
   *
   *
   */
  const validateForm = () => {
    if (!password) {
      throw new Error('Please Enter Password');
    }
  };

  /**
   *
   *
   */
  const verify = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const url = new URLSearchParams(location.search);
    const data = url.get('data');
    const code = url.get('code');
    try {
      validateForm();
      if (data) {
        const decodededData = JSON.parse(atob(data));
        const username = decodededData?.username;
        if (username) {
          await Auth.forgotPasswordSubmit(username, code as string, password);
          toaster('Password has been reset');
          navigate('/login/');
        } else {
          throw new Error('Invalid Data');
        }
      }
    } catch (err:any) {
      toaster(err.message, 'error');
      setLoading(false);
    }
  };

  /**
   *
   *
   * @param {*} e
   */
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
  };

  useEffect(() => {
    (async () => {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      const sub = currentAuthUser?.attributes?.sub;
      await changeSync(sub);
    })();
  }, []);

  return (
    <div className={styles.verifyEmail}>
      <form onSubmit={verify}>
        <h4>Enter new password</h4>
        <label htmlFor="password">
          Password *
          <input
            type="password"
            id="password"
            onChange={handleChange}
            placeholder="Enter new password"
            required
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading
            ? (
              <div className={styles.spinner}>
                <div />
                <div />
                <div />
                <div />
              </div>
            ) : 'reset'}
        </button>
      </form>
    </div>
  );
};

export default VerifyEmail;
